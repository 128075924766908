import * as React from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Link,
  Pagination,
  Checkbox,
} from "@mui/material";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useContext } from "react";
import {
  studentTableBox,
  studentTableHeaderCell,
  studentTableCellWide,
  studentTablePaper,
  studentTableSpan,
  studentTableCell,
  studentTableHeadCell,
  studentTableHeadCellActive,
  studentsTableHeaderText,
  studentTableCellTeacher,
  boxStyle,
  studentTableAEID,
} from "./studentsTable.style";
import { tableAvatar, paginationContent } from "./sharedStyles";
import StudentUserCard from "./StudentInfo/UserCard/StudentUserCard";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import studentsService from "../service/studentsService";

const headCells = [
  {
    id: "student",
    numeric: false,
    disablePadding: true,
    label: "NAME",
    sortable: true,
  },
  {
    id: "medical_badge",
    numeric: false,
    disablePadding: true,
    label: "",
    sortable: false,
    width: "5%",
  },
  {
    id: "teacher",
    numeric: false,
    disablePadding: true,
    label: "HOMEROOM TEACHER",
    sortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "STATUS",
    sortable: true,
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    numSelected,
    rowCount,
    onSelectAllClick,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isHeaderActive = (id, sortable) =>
    orderBy === id
      ? studentTableHeadCellActive(sortable)
      : studentTableHeadCell(sortable);

  const renderSortChevron = (id) => {
    if (id !== orderBy) return "";

    return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={isHeaderActive(false)}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            sx={{ ml: 0.5, border: 0 }}
            inputProps={{
              "aria-label": "select all students",
            }}
          />
        </TableCell>
        <TableCell sx={studentTableHeadCell(false)} />
        {headCells.map((headCell) => (
          <TableCell
            {...(headCell.sortable && {
              onClick: createSortHandler(headCell.id),
            })}
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={isHeaderActive(headCell.id, headCell.sortable)}
            width={headCell.width}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Box sx={studentsTableHeaderText}>
              {headCell.label}
              {renderSortChevron(headCell.id)}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function StudentsTable({
  isCompact,
  students,
  searchParams,
  setSearchParams,
  selected,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  setSelected,
  setPage,
  totalPages,
  page,
  gradeLevel,
}) {
  // eslint-disable-next-line no-unused-vars
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const location = useLocation();
  const params = useParams();
  const schoolId = params.school_id;
  const [allStudents, setAllStudents] = React.useState([]);
  const mappedStudents = isCompact ? allStudents : students;
  const [currentStudentId, setCurrentStudentId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const showStudentUserCard = Boolean(anchorEl);
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);

  const studentAccountPermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_ACCOUNT
  );
  const studentProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_PROFILE
  );

  const medicalProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_MEDICAL
  );

  const eventsProfilePermission = hasAnyPermissionType(
    APP_PERMISSIONS.STUDENT_EVENTS
  );

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_LIST,
    PERMISSION_TYPES.MANAGE
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((student) => student.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  const homeroomTeacher = (student) =>
    student.teacher
      ? `${student.teacher.first_name} ${student.teacher.last_name}`
      : "-";

  const avatarClick = async (event, studentId) => {
    setCurrentStudentId(studentId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateStudentLink = (_student) => {
    if (studentAccountPermission && location.pathname.includes("account"))
      return `/school/${schoolId}/students/${_student.slug}/account`;
    if (studentProfilePermission && location.pathname.includes("profile"))
      return `/school/${schoolId}/students/${_student.slug}/profile`;
    if (medicalProfilePermission && location.pathname.includes("medical"))
      return `/school/${schoolId}/students/${_student.slug}/medical`;
    if (eventsProfilePermission && location.pathname.includes("events"))
      return `/school/${schoolId}/students/${_student.slug}/events`;
    if (location.pathname.includes("messages")) {
      return `/school/${schoolId}/students/${_student.slug}/messages`;
    }
    return `/school/${schoolId}/students/${_student.slug}/account`;
  };

  function handleProfilePictureSrc(student) {
    if (student?.image_url) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
          alt="profile_image"
          src={student.image_url.url}
          style={{
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            objectFit: "cover",
          }}
          onClick={(e) => {
            avatarClick(e, student.slug);
          }}
        />
      );
    }
    return (
      <Avatar
        sx={tableAvatar}
        onClick={(e) => {
          avatarClick(e, student.slug);
        }}
      >
        {student.first_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  const getAllStudents = async () => {
    const response = await studentsService.fetchAll({
      params: { school_id: schoolId, gradeLevel },
    });
    if (response.data) {
      setAllStudents(response.data);
    }
  };

  React.useEffect(() => {
    if (isCompact) {
      getAllStudents();
    }
  }, [isCompact]);

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the row
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id);
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box sx={studentTableBox}>
      <Container maxWidth="lg">
        {students === undefined ? (
          "Loading..."
        ) : (
          <Paper sx={studentTablePaper} elevation={0}>
            <TableContainer>
              <Box sx={isCompact ? boxStyle : {}}>
                <Table aria-labelledby="tableTitle" size="medium">
                  {!isCompact && (
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={students.length}
                    />
                  )}
                  <TableBody>
                    {mappedStudents.map((_student) => {
                      const isItemSelected = isSelected(_student.id);
                      const labelId = `enhanced-table-checkbox-${_student.slug}`;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={_student.slug}
                          selected={isItemSelected}
                        >
                          {!isCompact && (
                            <>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                sx={studentTableHeaderCell(isCompact)}
                              >
                                <Checkbox
                                  checked={isItemSelected}
                                  onClick={(event) =>
                                    handleCheckboxClick(event, _student.id)
                                  }
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                sx={studentTableHeaderCell(isCompact)}
                              >
                                {handleProfilePictureSrc(_student)}
                              </TableCell>
                            </>
                          )}

                          {managePermission ? (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={studentTableCellWide(isCompact, true)}
                            >
                              <Link
                                to={generateStudentLink(_student)}
                                underline="none"
                                component={RouterLink}
                              >
                                <span style={studentTableSpan(isCompact)}>
                                  {`${_student.last_name}, ${_student.first_name}`}{" "}
                                  {_student.middle_name || ""}
                                </span>
                              </Link>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={studentTableCellWide(isCompact, false)}
                            >
                              <Box>
                                <span style={studentTableSpan(isCompact)}>
                                  {`${_student.last_name}, ${
                                    _student.first_name
                                  } ${_student.middle_name || ""}`}
                                </span>
                              </Box>
                            </TableCell>
                          )}
                          <TableCell>
                            {_student.medical_badge && (
                              <PrivacyTipOutlinedIcon />
                            )}
                          </TableCell>
                          {!isCompact && (
                            <>
                              <TableCell
                                align="center"
                                sx={studentTableCellTeacher}
                              >
                                {homeroomTeacher(_student)}
                              </TableCell>
                              <TableCell align="center" sx={studentTableCell}>
                                {_student.student_status}
                              </TableCell>
                            </>
                          )}
                          {isCompact && (
                            <TableCell align="center" sx={studentTableAEID}>
                              {_student.ae_id}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
            {!isCompact && (
              <Pagination
                count={totalPages}
                onChange={handleChangePage}
                sx={paginationContent}
                page={parseInt(page, 10)}
              />
            )}
          </Paper>
        )}
      </Container>
      <StudentUserCard
        studentId={currentStudentId}
        schoolId={schoolId}
        handleClose={handleClose}
        showStudentUserCard={showStudentUserCard}
        anchorEl={anchorEl}
      />
    </Box>
  );
}
