import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import moment from "../../../utils/constants/momentConfig";
import studentsService from "../../../service/studentsService";
import {
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  rightDrawerContainer,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import { mediumDate } from "../../../utils/dateTimePresets";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function EnrollmentsVersion({ setEnrollmentsVersionOpen }) {
  const params = useParams();
  const [enrollmentsHistory, setEnrollmentsHistory] = useState([]);
  const methods = useForm({ mode: "onChange" });
  const { setSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const fetchEnrollmentHistory = async () => {
      const history = await studentsService.fetchEnrollmentsHistory(
        params.student_slug,
        {
          school_id: params.school_id,
        }
      );
      setEnrollmentsHistory(history);
    };

    fetchEnrollmentHistory();
  }, [params.student_slug, params.school_id]);

  const isDateWithinSchoolYear = (date, schoolYear) => {
    if (
      !date ||
      !schoolYear ||
      !schoolYear.start_date ||
      !schoolYear.end_date
    ) {
      return false;
    }

    // Ensure all dates are treated as UTC and only consider the date part
    const dateToCheck = moment.utc(date).startOf("day");
    const startDate = moment.utc(schoolYear.start_date).startOf("day");
    const endDate = moment.utc(schoolYear.end_date).startOf("day");

    const result = dateToCheck.isBetween(startDate, endDate, "day", "[]");

    return result;
  };

  const handleDateChange = (index, newDate) => {
    const updatedHistory = [...enrollmentsHistory];
    const formattedDate = newDate ? newDate.toISOString() : null;
    updatedHistory[index].date = formattedDate;
    updatedHistory[index].dateError =
      formattedDate &&
      !isDateWithinSchoolYear(formattedDate, updatedHistory[index].school_year)
        ? `Date must be within the school year.`
        : "";
    setEnrollmentsHistory(updatedHistory);
  };

  const handleWithdrawalDateChange = (index, newDate) => {
    const updatedHistory = [...enrollmentsHistory];
    const formattedDate = newDate ? newDate.toISOString() : null;
    updatedHistory[index].withdrawal_date = formattedDate;
    updatedHistory[index].withdrawalDateError =
      formattedDate &&
      !isDateWithinSchoolYear(formattedDate, updatedHistory[index].school_year)
        ? `Date must be within the school year.`
        : "";
    setEnrollmentsHistory(updatedHistory);
  };

  const onSubmit = () => {
    studentsService.updateEnrollmentHistory(
      params.student_slug,
      enrollmentsHistory
    );
    setEnrollmentsVersionOpen(false);
    setSnackbar({
      message: "Enrollments history updated",
      open: true,
      severity: "success",
    });
  };

  return (
    <Box sx={rightDrawerContainer}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack direction="row" sx={formHeader}>
            <Typography sx={formTitle}>History of enrollment</Typography>
            <Stack direction="row">
              <Button
                sx={formButtons}
                onClick={() => setEnrollmentsVersionOpen(false)}
              >
                Cancel
              </Button>
              <GenericSubmitButton
                text="Delete"
                submittingText="Deleting..."
                type="button"
                onClick={() => {
                  // setAlertDialogOpen(true);
                }}
              />
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Stack>
          <Box sx={formContainer}>
            {enrollmentsHistory.map((enrollment, index) => (
              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography sx={{ ...formTitle, mb: 3, mr: 3 }}>
                    {enrollment.school_year?.name}{" "}
                  </Typography>
                  <Typography>
                    {mediumDate(enrollment.school_year?.start_date, {
                      timeZone: "UTC",
                    })}{" "}
                    -
                    {mediumDate(enrollment.school_year?.end_date, {
                      timeZone: "UTC",
                    })}
                  </Typography>
                </Box>
                <Box
                  key={enrollment.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <DesktopDatePicker
                    label="Enrollment Date"
                    inputFormat="MM/DD/YYYY"
                    value={enrollment.date ? moment.utc(enrollment.date) : null}
                    onChange={(newDate) => handleDateChange(index, newDate)}
                    renderInput={(renderParams) => (
                      <TextField
                        {...renderParams}
                        required
                        error={
                          !enrollment.date || Boolean(enrollment.dateError)
                        }
                        helperText={
                          !enrollment.date
                            ? "This field is required."
                            : enrollment.dateError
                        }
                        sx={{ mr: 10 }}
                      />
                    )}
                  />

                  <DesktopDatePicker
                    label="Withdrawn Date"
                    inputFormat="MM/DD/YYYY"
                    value={
                      enrollment.withdrawal_date
                        ? moment.utc(enrollment.withdrawal_date)
                        : null
                    }
                    onChange={(newDate) =>
                      handleWithdrawalDateChange(index, newDate)
                    }
                    renderInput={(renderParams) => (
                      <TextField
                        {...renderParams}
                        required={false}
                        error={Boolean(enrollment.withdrawalDateError)}
                        helperText={enrollment.withdrawalDateError}
                      />
                    )}
                    clearable
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}
