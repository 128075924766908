import { styled } from "@mui/material/styles";
import { Tabs } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppTheme from "../utils";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})((props) => {
  const drawerWidth = useMediaQuery(AppTheme.breakpoints.down("xl"))
    ? 350
    : 400;

  return {
    flexGrow: 1,
    transition: AppTheme.transitions.create("margin", {
      easing: AppTheme.transitions.easing.sharp,
      duration: AppTheme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(props.open && {
      transition: AppTheme.transitions.create("margin", {
        easing: AppTheme.transitions.easing.easeOut,
        duration: AppTheme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    paddingRight: props.open && "40px",
  };
});

const DrawerHeader = styled("div")(() => ({
  position: "absolute",
  right: 0,
  paddingTop: "23px",
}));

const CssTabs = styled(Tabs)({
  "&.MuiTabs-root": {},
  "& .MuiTab-root": {
    padding: 0,
    minWidth: 0,
    marginRight: 24,
    textTransform: "none",
    color: AppTheme.palette.primary.main,
  },
  "& .MuiTab-root.Mui-selected": {},
});

const studentInfoDrawer = (theme) => {
  const drawerWidth = useMediaQuery(theme.breakpoints.down("xl")) ? 400 : 600;

  return {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      position: "relative",
      width: drawerWidth,
      boxSizing: "border-box",
    },
  };
};

const studentArrowIcon = { cursor: "pointer", transform: "scaleX(-1)" };

const studentInfoStackArrowIcon = (open) => ({
  cursor: "pointer",
  visibility: open ? "hidden" : "visible",
  position: "relative",
  right: "40px",
});

const studentInfoHighlightedLink = {
  color: "#5077A4",
  fontWeight: "bold",
  fontSize: 14,
  display: "flex",
  alignItems: "start",
};

const studentInfoTabBox = { width: "100%" };

const studentInfoTabContainer = (open) => ({
  px: open ? "25px !important" : "65px !important",
});

const studentInfoTabBorder = {
  borderBottom: 1,
  borderColor: "primary.main",
};

const studentInfoTabPanelSpacing = (theme, open) => ({
  maxWidth:
    useMediaQuery(theme.breakpoints.down("xl")) && open ? "1035px" : "1420px",
  margin:
    useMediaQuery(theme.breakpoints.down("xl")) && open ? "0 0 0 25px" : "auto",
  pl: open ? "0" : "5px",
});

export {
  Main,
  DrawerHeader,
  CssTabs,
  studentInfoStackArrowIcon,
  studentInfoHighlightedLink,
  studentArrowIcon,
  studentInfoDrawer,
  studentInfoTabBorder,
  studentInfoTabBox,
  studentInfoTabPanelSpacing,
  studentInfoTabContainer,
};
