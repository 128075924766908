import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import progressReportService from "../../../service/progressReportService";
import ProgressReportBody from "../../Reports/ProgressReport/ProgressReportBody";

export default function NotificationProgressReport({ notification }) {
  const { data } = notification;
  const [report, setReport] = useState();

  useEffect(() => {
    if (data) {
      progressReportService
        .fetchProgressReport(data.progress_report_id)
        .then((response) => {
          setReport(response.data);
        })
        .catch(() => {});
    }
  }, [data]);

  if (!report) return <span />;

  return (
    <Box
      sx={{ maxWidth: 1000, margin: "0 auto", background: "white", padding: 2 }}
    >
      <ProgressReportBody report={report} />
    </Box>
  );
}
