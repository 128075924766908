import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  Grid,
  Switch,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { m0, mt15, mx5, font26 } from "../../../sharedStyles";

const availablePreferences = [
  {
    name: "academic",
    events: [
      {
        event_name: "progress_report",
        options: {
          name: "frequency",
          values: ["weekly", "daily"],
        },
      },
      {
        event_name: "assignment_missing_guardian",
        options: {
          name: "type",
          values: ["missing"],
        },
      },
    ],
  },
];

function NotificationsSettings({ setting, enabled }) {
  const { t } = useTranslation("notifications");
  const { setValue } = useFormContext();
  const [data, setData] = useState({ enabled: setting.enabled });
  const [events, setEvents] = useState(setting.preferences || []);

  const getEventSet = (eventName) =>
    events.filter((e) => e.event_name === eventName)[0];

  const isCheckedEvent = (name) =>
    events.filter((e) => e.event_name === name).length > 0 || false;

  const isCheckedChannel = (name, channel) =>
    getEventSet(name)[channel] || false;

  const handleSettingChange = (e) => {
    const { checked } = e.target;
    setData({ enabled: checked });
  };

  const handleEventChange = (e) => {
    const { checked, value } = e.target;
    const currentSets = events.filter((c) => c.event_name !== value);

    if (checked) {
      setEvents([
        ...currentSets,
        { event_name: value, email: true, text: false },
      ]);
    } else {
      setEvents(currentSets);
    }
  };

  const handleChannelChange = (e, eventName) => {
    const { checked, value } = e.target;
    const currentSets = events.filter((c) => c.event_name !== eventName);
    const targetSet = getEventSet(eventName);
    setEvents([...currentSets, { ...targetSet, [value]: checked }]);
  };

  const handleOptionChange = (e, eventName) => {
    const { value } = e.target;
    const currentSets = events.filter((c) => c.event_name !== eventName);
    const targetSet = getEventSet(eventName);
    setEvents([...currentSets, { ...targetSet, option: value }]);
  };

  useEffect(() => {
    setValue("notifications.preferences", events);
  }, [events]);

  useEffect(() => {
    setValue("notifications.settings", data);
  }, [data]);

  return (
    <Box>
      <Grid
        sx={mt15}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid>
          <Typography>
            {t("receiveNotifications", { keyPrefix: "settings" })}
          </Typography>
        </Grid>

        <Grid>
          {enabled ? (
            <Switch
              color="info"
              checked={data?.enabled || false}
              onChange={handleSettingChange}
            />
          ) : (
            <Tooltip
              title="Portal Access should be enabled"
              placement="bottom-start"
              arrow
            >
              <span>
                <Switch disabled />
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      {availablePreferences.map((category) => (
        <Box key={category.name} className="eventOption">
          <Grid container>
            <Grid item xs={8}>
              <Box className="optionsTitle">
                {t(category.name, { keyPrefix: "categories" })}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Grid container className="optionsHeaders">
                <Grid item xs={6}>
                  <Typography align="center">
                    {t("email", { keyPrefix: "settings" })}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography align="center">
                    {t("text", { keyPrefix: "settings" })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {category.events.map((event) => (
            <Grid container key={event.event_name}>
              <Grid item xs={8}>
                <FormGroup sx={mx5} className="eventCheckmarkOption">
                  <FormControlLabel
                    label={t("settingsLabel", {
                      keyPrefix: `events.${event.event_name}`,
                    })}
                    sx={m0}
                    control={
                      <Checkbox
                        size="small"
                        disabled={!enabled || !data?.enabled}
                        value={event.event_name}
                        checked={isCheckedEvent(event.event_name)}
                        onChange={handleEventChange}
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              {isCheckedEvent(event.event_name) && (
                <Grid item xs={4} className="emailTextOptions">
                  <Grid container>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          sx={m0}
                          control={
                            <Checkbox
                              size="small"
                              disabled={!enabled || !data?.enabled}
                              value="email"
                              checked={isCheckedChannel(
                                event.event_name,
                                "email"
                              )}
                              onChange={(e) =>
                                handleChannelChange(e, event.event_name)
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          sx={m0}
                          control={
                            <Checkbox
                              size="small"
                              disabled={true || !enabled || !data?.enabled}
                              value="text"
                              checked={isCheckedChannel(
                                event.event_name,
                                "text"
                              )}
                              onChange={(e) =>
                                handleChannelChange(e, event.event_name)
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Box>
      ))}

      <Grid>
        <Typography mt={4} sx={font26}>
          {t("options", { keyPrefix: "settings" })}
        </Typography>
      </Grid>

      {availablePreferences.map((category) => (
        <Box key={category.name} className="eventOption">
          <Box className="optionsTitle">
            {t(category.name, { keyPrefix: "categories" })}
          </Box>

          {category.events.map((event) => (
            <Grid container key={event.event_name} sx={{ mt: 2 }}>
              <Grid item xs={7}>
                <FormGroup sx={mx5} className="eventCheckmarkOption">
                  <FormControlLabel
                    label={t("settingsLabel", {
                      keyPrefix: `events.${event.event_name}`,
                    })}
                    sx={m0}
                    control={
                      <Checkbox
                        size="small"
                        disabled
                        value={event.event_name}
                        checked={isCheckedEvent(event.event_name)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              {isCheckedEvent(event.event_name) && (
                <Grid item xs={5}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel>
                      {t("optionName", {
                        keyPrefix: `events.${event.event_name}`,
                      })}
                    </InputLabel>
                    <Select
                      disabled={!enabled || !data?.enabled}
                      value={
                        getEventSet(event.event_name).option ||
                        event.options.values[0]
                      }
                      label={t("optionName", {
                        keyPrefix: `events.${event.event_name}`,
                      })}
                      onChange={(e) => handleOptionChange(e, event.event_name)}
                    >
                      {event.options.values.map((value) => (
                        <MenuItem key={value} value={value}>
                          {t(value, {
                            keyPrefix: `events.${event.event_name}.optionValues`,
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export default NotificationsSettings;
