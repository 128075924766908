import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { font11, font28, mb15, my30, loader } from "../../sharedStyles";
import {
  collectionLabelContainer,
  collectionInputField,
  collectionNameLabel,
  collectionItemsContainer,
  collectionSubmitBtn,
  conllectionBtnContainer,
} from "./SchoolDaysSettings.styles";
import SnackBarNotification from "../../SnackBarNotification";
import { renderError, requiredMessage } from "../../../utils/constants/forms";
import schoolDayDesignationsService from "../../../service/schoolDayDesignationsService";

export default function SchoolDaysSettings({ managePermission }) {
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "designations",
  });

  const getSchoolDayDesignations = async () => {
    setLoading(true);
    const response = await schoolDayDesignationsService.fetchAll();

    if (response.data) {
      if (response.data.length > 0) {
        response.data.forEach((item, index) => {
          append({
            itemId: item.id,
            name: item.name,
            isDeleted: false,
            category: item.category,
          });
          setValue(`designations.${index}.name`, item.name);
          setValue(`designations.${index}.category`, item.category);
        });
      } else {
        append({ value: "", isDeleted: false });
      }

      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const designationsToDelete = data.designations.filter(
      (designation) => designation.isDeleted === true
    );
    const designationsToUpdate = data.designations.filter(
      (designation) => designation.isDeleted === false && designation.itemId
    );
    const designationsToCreate = data.designations.filter(
      (designation) => designation.isDeleted === false && !designation.itemId
    );

    const requestsList = [];

    if (designationsToDelete.length > 0) {
      requestsList.push(
        schoolDayDesignationsService.bulkDelete({
          params: {
            designation_ids: designationsToDelete.map((m) => m.itemId),
          },
        })
      );
    }

    if (designationsToUpdate.length > 0) {
      requestsList.push(
        schoolDayDesignationsService.bulkUpdate({
          designations: designationsToUpdate,
        })
      );
    }

    if (designationsToCreate.length > 0) {
      requestsList.push(
        schoolDayDesignationsService.bulkCreate({
          designations: designationsToCreate,
        })
      );
    }

    Promise.all(requestsList)
      .then(() => {
        setSnackBarOpen({
          open: true,
          message: "Collections updated.",
        });
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = async (index, id) => {
    if (id) {
      setValue(`designations.${index}.isDeleted`, true);
    } else {
      remove(index);
    }
  };

  const handleUndoDelete = (index) => {
    setValue(`designations.${index}.isDeleted`, false);
  };

  const requiredValidation = {
    value: true,
    message: requiredMessage,
  };

  useEffect(() => {
    getSchoolDayDesignations();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={my30}>
      <Typography sx={font28}>School Days</Typography>
      <Stack direction="row" sx={collectionLabelContainer}>
        <Typography sx={collectionNameLabel}>NAME</Typography>
        <Typography sx={[font11, collectionItemsContainer]}>
          SCHOOL DAY
        </Typography>
        <Typography sx={[font11, collectionItemsContainer]}>
          NON-SCHOOL DAY
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {fields.map((field, index) => (
            <Box key={field.id}>
              <Stack direction="row" alignItems="center" sx={mb15}>
                <TextField
                  error={errors?.designations?.[index]}
                  sx={collectionInputField}
                  disabled={!managePermission}
                  label="Name"
                  {...register(`designations.${index}.name`, {
                    required: requiredValidation,
                  })}
                />
                <FormControl sx={{ ml: 5 }}>
                  <Controller
                    name={`designations.${index}.category`}
                    defaultValue=""
                    control={control}
                    rules={{
                      required: requiredValidation,
                    }}
                    render={({ field: renderField }) => (
                      <RadioGroup row {...renderField}>
                        <FormControlLabel
                          value="school_day"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="non_school_day"
                          control={<Radio />}
                          sx={{ ml: 5 }}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                {managePermission && (
                  <Box>
                    {!watch(`designations.${index}.isDeleted`) ? (
                      <Typography
                        sx={{ cursor: "pointer", mt: "5px" }}
                        onClick={() => handleDelete(index, field.itemId)}
                      >
                        <CloseIcon />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleUndoDelete(index)}
                      >
                        Undo
                      </Typography>
                    )}
                  </Box>
                )}
              </Stack>

              {errors?.designations?.[index] &&
                renderError(errors?.designations?.[index]?.value?.message)}
            </Box>
          ))}
        </Box>
        {managePermission && (
          <Stack
            sx={conllectionBtnContainer}
            direction="row"
            justifyContent="space-between"
          >
            <Button
              sx={collectionSubmitBtn}
              startIcon={<AddIcon />}
              variant="text"
              component="label"
              onClick={() =>
                append({ value: "", isDeleted: false, category: "school_day" })
              }
            >
              Add New School Day Designation
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Stack>
        )}
      </form>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
