import React from "react";
import { Button, Stack } from "@mui/material";
import {
  filterByKlassBtn,
  filterByKlassBtnActive,
} from "../Subject/SubjectFilterByClass.styles";

export default function KlassSelect({
  klasses,
  setKlasses,
  manageAllStudentsPermission,
}) {
  const handleClick = (klassId) => {
    const updatedKlasses = klasses.map((klass) => ({
      ...klass,
      is_active: klass.id === klassId,
    }));
    setKlasses(updatedKlasses);
  };

  return (
    <Stack
      direction="row"
      sx={{ overflowX: "hidden", mt: manageAllStudentsPermission ? "15px" : 0 }}
    >
      {klasses.length > 0 &&
        klasses.map((klass) => (
          <Button
            onClick={() => handleClick(klass.id)}
            sx={klass.is_active ? filterByKlassBtnActive : filterByKlassBtn}
            key={klass.id}
          >
            {klass.abbreviation}
          </Button>
        ))}
    </Stack>
  );
}
