import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { Stack, Button, Tooltip } from "@mui/material";
import {
  codeBarInnerContainer,
  codeBarButton,
  codeBarButtonActive,
} from "./CodeBar.styles";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";

export default function CodeBar({ activeCode, setActiveCode, availableCodes }) {
  const { hasPermission } = useContext(PermissionsContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const managePermission = hasPermission(
    APP_PERMISSIONS.ATTENDANCE,
    PERMISSION_TYPES.MANAGE
  );

  const codeBarButtonStyle = (code) =>
    code === activeCode.code
      ? codeBarButtonActive(activeCode.color)
      : codeBarButton;

  const handleCodeChange = (currentCode) => {
    searchParams.set("code", currentCode.code);
    setActiveCode(currentCode);
    setSearchParams(searchParams);
  };

  return (
    <Stack direction="row" sx={codeBarInnerContainer}>
      {availableCodes.map((availableCode) => (
        <Tooltip
          title={availableCode.description}
          enterDelay={1000}
          key={availableCode.code}
        >
          <Button
            sx={codeBarButtonStyle(availableCode.code)}
            {...(managePermission && {
              onClick: () => handleCodeChange(availableCode),
            })}
          >
            {availableCode.code}
          </Button>
        </Tooltip>
      ))}
    </Stack>
  );
}
