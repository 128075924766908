import React from "react";
import { Route } from "react-router-dom";
import Reports from "../views/Reports";
import EmergencyInformation from "../components/Reports/EmergencyContacts/EmergencyInformation";
import KlassRoster from "../components/Reports/KlassRoster/KlassRoster";
import Assignments from "../components/Reports/Assignments/Assignments";
import SchoolCalendar from "../components/Reports/SchoolCalendar/SchoolCalendar";
import HomeroomRoster from "../components/Reports/HomeroomRoster/HomeroomRoster";
import Enrollment from "../components/Reports/Enrollment/Enrollment";
import ParentContacts from "../components/Reports/ParentContacts/ParentContacts";
import StudentProgress from "../components/Reports/StudentProgress/StudentProgress";
import Attendance from "../components/Reports/Attendance/Attendance";
import Birthdays from "../components/Reports/Birthdays/Birthdays";
import StudentList from "../components/Reports/StudentList/StudentList";
import ProgressReport from "../components/Reports/ProgressReport/ProgressReport";

export default [
  <Route path="/school/:school_id/reports" element={<Reports />} key={0}>
    <Route
      path="/school/:school_id/reports/Attendance"
      element={<Attendance />}
      key={1}
    />
    <Route
      path="/school/:school_id/reports/birthdays"
      element={<Birthdays />}
      key={2}
    />
    <Route
      path="/school/:school_id/reports/class-roster"
      element={<KlassRoster />}
      key={3}
    />
    <Route
      path="/school/:school_id/reports/emergency-info"
      element={<EmergencyInformation />}
      key={4}
    />
    <Route
      path="/school/:school_id/reports/enrollment"
      element={<Enrollment />}
      key={5}
    />
    <Route
      path="/school/:school_id/reports/homeroom-roster"
      element={<HomeroomRoster />}
      key={6}
    />
    <Route
      path="/school/:school_id/reports/report-assignments"
      element={<Assignments />}
      key={7}
    />
    <Route
      path="/school/:school_id/reports/parent-contacts"
      element={<ParentContacts />}
      key={8}
    />
    <Route
      path="/school/:school_id/reports/student-progress"
      element={<StudentProgress />}
      key={9}
    />
    <Route
      path="/school/:school_id/reports/school-calendar"
      element={<SchoolCalendar />}
      key={10}
    />
    <Route
      path="/school/:school_id/reports/student-list"
      element={<StudentList />}
      key={11}
    />
    <Route
      path="/school/:school_id/reports/progress-report"
      element={<ProgressReport />}
      key={12}
    />
  </Route>,
];
