import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { assignmentsTableStyles } from "./ProgressReport.style";
import { weekLongDate, twoDigitDate } from "../../../utils/dateTimePresets";

export default function ProgressReportBody({ report }) {
  const { t, i18n } = useTranslation("common");
  const { student, klasses } = report;

  const filteredAssignments = (category, studentAssignments) =>
    studentAssignments.filter(
      (sa) => sa.assignment.klass_category.id === category.id
    );

  const scoreSuffix = (grading) => {
    switch (grading) {
      case "percent":
        return "%";
      case "letter_grade":
        return "%";
      case "points":
        return ` ${t("points").toUpperCase()}`;
      default:
        return "";
    }
  };

  const scoreWithSuffix = (score, grading) => {
    if (!score || score === "") return "";
    return `${score}${scoreSuffix(grading)}`;
  };

  if (report.klasses.length === 0)
    return (
      <Box textAlign="center" mt={3}>
        No classes for the current filters
      </Box>
    );

  return (
    <Grid pt={3}>
      <Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #8F8F8F"
        >
          <Grid item>
            <Typography fontWeight={400} fontSize="24px" color="black">
              {student.last_name}, {student.first_name}
            </Typography>
          </Grid>

          <Grid item display="flex" alignItems="center" mr={5}>
            <Typography fontWeight={400} fontSize="12px" color="black">
              {t("grade", {
                keyPrefix: "grades",
                count: Number(student.grade_level),
                ordinal: true,
              })}
            </Typography>
            <Typography px={2}>|</Typography>
            <Typography fontWeight={400} fontSize="12px" color="black">
              {t("date")}:{" "}
              {weekLongDate(report.generated_at, { language: i18n.language })}
            </Typography>
          </Grid>
        </Grid>

        {klasses.map((klass) => (
          <Box>
            <Grid container>
              <Grid item sm={9}>
                <Typography fontSize="28px" mt={2} color="black">
                  {klass.name}
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography
                      mt={3}
                      textAlign="center"
                      fontWeight={600}
                      color="black"
                    >
                      {klass.term_grade_percentage}%
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography
                      fontSize="28px"
                      mt={2}
                      textAlign="center"
                      color="black"
                    >
                      {klass.term_letter_grade_partial}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {klass.klass_categories.map((category) => (
              <Box sx={{ breakInside: "avoid" }}>
                <Grid container>
                  <Grid item sm={9}>
                    <Typography
                      fontSize="18px"
                      fontWeight={600}
                      mt={5}
                      mb={1}
                      color="black"
                    >
                      {category.category}
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography
                      fontSize="12px"
                      mt={6}
                      mb={1}
                      textAlign="center"
                      color="black"
                    >
                      {t("weight")}={category.weight}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider />

                <Box sx={assignmentsTableStyles}>
                  <table>
                    <tr>
                      <th>{t("assignment")}</th>
                      <th>{t("score")}</th>
                      <th>{t("max")}</th>
                      <th>{t("due")}</th>
                      <th>{t("comment")}</th>
                    </tr>

                    {filteredAssignments(
                      category,
                      klass.student_assignments
                    ).map((assignmentStudent) => (
                      <tr
                        className={
                          assignmentStudent.missing ? "missing" : undefined
                        }
                      >
                        <td>{assignmentStudent.assignment.name}</td>
                        <td>
                          {assignmentStudent.missing
                            ? t("missing")
                            : scoreWithSuffix(
                                assignmentStudent.score_with_marks,
                                assignmentStudent.assignment.grading
                              )}
                        </td>
                        <td>
                          {!assignmentStudent.missing &&
                            scoreWithSuffix(
                              assignmentStudent.assignment.max_score,
                              assignmentStudent.assignment.grading
                            )}
                        </td>
                        <td>
                          {!assignmentStudent.missing &&
                            twoDigitDate(
                              assignmentStudent.assignment.due_date,
                              { language: i18n.language }
                            )}
                        </td>
                        <td>{assignmentStudent.comment}</td>
                      </tr>
                    ))}
                  </table>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
