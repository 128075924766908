import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  CircularProgress,
  FormControl,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { font11, font28, mb15, my30, loader } from "../../sharedStyles";
import {
  collectionLabelContainer,
  reasonInputField,
  collectionNameLabel,
  collectionItemsContainer,
  collectionSubmitBtn,
  conllectionBtnContainer,
} from "./WithdrawalReasons.styles";
import SnackBarNotification from "../../SnackBarNotification";
import { renderError, requiredMessage } from "../../../utils/constants/forms";
import withdrawalReasonsService from "../../../service/withdrawalReasonsService";

export default function WithdrawalReasons({ managePermission }) {
  const [loading, setLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "reasons",
  });

  const getWithdrawalReasons = async () => {
    setLoading(true);
    const response = await withdrawalReasonsService.fetchAll();

    if (response.data) {
      if (response.data.length > 0) {
        response.data.forEach((item, index) => {
          append({
            itemId: item.id,
            description: item.description,
            isDeleted: false,
            active: item.active,
          });
          setValue(`reasons.${index}.description`, item.description);
          setValue(`reasons.${index}.active`, item.active);
        });
      } else {
        append({ value: "", isDeleted: false });
      }

      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const reasonsToDelete = data.reasons.filter(
      (reason) => reason.isDeleted === true
    );
    const reasonsToUpdate = data.reasons.filter(
      (reason) => reason.isDeleted === false && reason.itemId
    );
    const reasonsToCreate = data.reasons.filter(
      (reason) => reason.isDeleted === false && !reason.itemId
    );

    const requestsList = [];

    if (reasonsToDelete.length > 0) {
      requestsList.push(
        withdrawalReasonsService.bulkDelete({
          params: {
            withdrawal_reasons_ids: reasonsToDelete.map((m) => m.itemId),
          },
        })
      );
    }

    if (reasonsToUpdate.length > 0) {
      requestsList.push(
        withdrawalReasonsService.bulkUpdate({
          withdrawal_reasons: reasonsToUpdate,
        })
      );
    }

    if (reasonsToCreate.length > 0) {
      requestsList.push(
        withdrawalReasonsService.bulkCreate({
          withdrawal_reasons: reasonsToCreate,
        })
      );
    }

    Promise.all(requestsList)
      .then(() => {
        setSnackBarOpen({
          open: true,
          message: "WithdrawalReasons updated.",
        });
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setSnackBarOpen({
          open: true,
          message: error.response?.data?.data?.message || "Error updating.",
          severity: "error",
        });
      });
  };

  const handleDelete = async (index, id) => {
    if (id) {
      setValue(`reasons.${index}.isDeleted`, true);
    } else {
      remove(index);
    }
  };

  const handleUndoDelete = (index) => {
    setValue(`reasons.${index}.isDeleted`, false);
  };

  const requiredValidation = {
    value: true,
    message: requiredMessage,
  };

  useEffect(() => {
    getWithdrawalReasons();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Box sx={my30}>
      <Typography sx={font28}>Reasons for Withdrawal</Typography>
      <Stack direction="row" sx={collectionLabelContainer}>
        <Typography sx={collectionNameLabel}>REASON</Typography>
        <Typography sx={[font11, collectionItemsContainer]}>ACTIVE</Typography>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {fields.map((field, index) => (
            <Box key={field.id}>
              <Stack direction="row" alignItems="center" sx={mb15}>
                <TextField
                  error={errors?.reasons?.[index]}
                  sx={reasonInputField}
                  disabled={!managePermission}
                  label="Description"
                  {...register(`reasons.${index}.description`, {
                    required: requiredValidation,
                  })}
                />
                <FormControl sx={{ ml: "29px" }}>
                  <Controller
                    name={`reasons.${index}.active`}
                    defaultValue=""
                    control={control}
                    render={({ field: renderField }) => (
                      <Checkbox
                        {...renderField}
                        checked={!!renderField.value}
                        onChange={(e) => renderField.onChange(e.target.checked)}
                      />
                    )}
                  />
                </FormControl>
                {managePermission && (
                  <Box>
                    {!watch(`reasons.${index}.isDeleted`) ? (
                      <Typography
                        sx={{ cursor: "pointer", mt: "5px" }}
                        onClick={() => handleDelete(index, field.itemId)}
                      >
                        <CloseIcon />
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleUndoDelete(index)}
                      >
                        Undo
                      </Typography>
                    )}
                  </Box>
                )}
              </Stack>

              {errors?.reasons?.[index] &&
                renderError(errors?.reasons?.[index]?.value?.message)}
            </Box>
          ))}
        </Box>
        {managePermission && (
          <Stack
            sx={conllectionBtnContainer}
            direction="row"
            justifyContent="space-between"
          >
            <Button
              sx={collectionSubmitBtn}
              startIcon={<AddIcon />}
              variant="text"
              component="label"
              onClick={() =>
                append({ value: "", isDeleted: false, active: true })
              }
            >
              Add New Reasons for Withdrawal
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Stack>
        )}
      </form>

      <SnackBarNotification
        message={snackBarOpen.message}
        open={snackBarOpen.open}
        severity={snackBarOpen.severity}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Box>
  );
}
