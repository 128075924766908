import React, { useState, useEffect, useContext } from "react";
import {
  useParams,
  useSearchParams,
  Link as RouterLink,
  useOutletContext,
} from "react-router-dom";
import {
  Container,
  Grid,
  Box,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Drawer,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  pageHeaderTitle,
  mr10,
  py30,
  mr30,
  rightDrawerContainer,
  loader,
  black,
  gcLogoContainer,
} from "../components/sharedStyles";
import {
  divider,
  subjectsListTable,
  subjectsListHead,
  subjectsListCell,
  subjectsListCellName,
  tableContainer,
  hidden,
} from "./MySubjects.styles";
import {
  klassesListName,
  klassesListSubject,
  klassesListDescription,
  klassesSettingsCell,
} from "./MyKlasses.styles";
import klassesService from "../service/klassesService";
import KlassForm from "../components/Klass/KlassForm";
import gcLogoPath from "../utils/constants/googleClassroom";
import TermSelect from "../components/TermSelect";
import { PermissionsContext } from "../context/PermissionsContext";
import APP_PERMISSIONS from "../utils/constants/permissions";
import PERMISSION_TYPES from "../utils/constants/permission_types";
import Authorize from "../components/Authorize";

export default function MyKlasses() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const schoolId = params.school_id;
  const [
    klassesDrawerStatus,
    setSnackBarOpen,
    teacher,
    ,
    ,
    ,
    ,
    ,
    user,
    ,
    ,
    currentSchool,
  ] = useOutletContext();
  const [klasses, setKlasses] = useState([]);
  const [order, setOrder] = useState(searchParams.get("direction") || "desc");
  const [orderBy, setOrderBy] = useState(searchParams.get("sort") || "name");
  const [klassesDrawerStatusEdit, setKlassesDrawerStatusEdit] = useState(false);
  const [activeKlass, setActiveKlass] = useState(null);
  const [menuAnchors, setMenuAnchors] = useState({});
  const [loading, setLoading] = useState(false);
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.MY_KLASSES,
    PERMISSION_TYPES.MANAGE
  );

  const handleClick = (event, id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleClose = (id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleGoogleLogin = async (klassId) => {
    if (!user.has_google_access) {
      const redirectParams = {
        klass_id: klassId,
        school_id: schoolId,
        user_id: user.id,
      };
      const encodedParams = btoa(JSON.stringify(redirectParams));

      window.location.replace(`/auth/google_oauth2?state=${encodedParams}`);
    } else {
      window.location.replace(
        `/school/${schoolId}/klasses/${klassId}/class-select`
      );
    }
  };

  const getKlasses = async () => {
    if (!klassesDrawerStatus && !klassesDrawerStatusEdit) {
      setLoading(true);
    }

    const response = await klassesService.fetchAllKlasses({
      params: {
        school_id: schoolId,
        subject_id: null,
        order,
        orderBy,
        term_id: searchParams.get("term"),
        prek: false,
      },
    });
    if (response.data) {
      setKlasses(response.data.klasses);
    }
    setLoading(false);
  };

  const getActiveKlass = async (klassId) => {
    const response = await klassesService.fetchKlass(klassId);
    if (response.data) {
      setActiveKlass(response.data);
    }
  };

  const renderSortChevron = (label) => {
    const noColumnSelected = searchParams.get("sort") === null;

    if ((noColumnSelected && label === "name") || label === orderBy) {
      return order === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return <ArrowDropUpIcon sx={hidden} />;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    searchParams.set("sort", property);
    searchParams.set("direction", direction);
    setSearchParams(searchParams);
  };

  const handleKlassesDrawer = (status, klassId = null) => {
    handleClose(klassId);

    setKlassesDrawerStatusEdit(status);
    if (status === false) {
      setActiveKlass(null);
    } else if (klassId) {
      getActiveKlass(klassId);
    }
  };

  const { hasAnyPermissionType } = useContext(PermissionsContext);

  const klassLink = (klass) => {
    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_STUDENTS)) {
      return `/school/${schoolId}/subjects/${
        klass.subject_id
      }/students?classes=${klass.id}&term=${searchParams.get("term")}`;
    }

    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_ASSIGNMENTS)) {
      return `/school/${schoolId}/subjects/${
        klass.subject_id
      }/assignments?classes=${klass.id}&term=${searchParams.get("term")}`;
    }

    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_GRADEBOOK)) {
      return `/school/${schoolId}/subjects/${
        klass.subject_id
      }/gradebook?classes=${klass.id}&term=${searchParams.get("term")}`;
    }

    if (hasAnyPermissionType(APP_PERMISSIONS.KLASSES_TERM_GRADES)) {
      return `/school/${schoolId}/subjects/${
        klass.subject_id
      }/term-grade/class-grade?classes=${klass.id}&term=${searchParams.get(
        "term"
      )}`;
    }

    return ``;
  };

  useEffect(() => {
    if (searchParams.get("term") !== "select" && !klassesDrawerStatusEdit) {
      getKlasses();
      setOrder(searchParams.get("direction") || "desc");
      setOrderBy(searchParams.get("sort") || "name");
    }
  }, [order, orderBy, klassesDrawerStatusEdit, searchParams.get("term")]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <Authorize permission={hasAnyPermissionType(APP_PERMISSIONS.MY_KLASSES)}>
      <Box sx={tableContainer}>
        <Grid container justifyContent="between" alignItems="center">
          <Grid item md={4}>
            <Typography sx={pageHeaderTitle}>Classes</Typography>
          </Grid>
          <Grid item md={6} />
          <Grid
            item
            md={2}
            container
            alignItems="center"
            sx={{ height: "55px" }}
          >
            <TermSelect
              schoolId={schoolId}
              fetchUpcomingTerms
              findCurrentTerm
              showDefault
            />
          </Grid>
        </Grid>
        <Divider sx={divider} />
      </Box>
      <Container maxWidth={false} variant="header">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sm={12}>
              {klasses.length > 0 ? (
                <TableContainer sx={py30}>
                  <Table sx={subjectsListTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={subjectsListHead(false, orderBy === "name")}
                          onClick={() => handleRequestSort("name")}
                        >
                          <Typography sx={klassesListName}>
                            NAME {renderSortChevron("name")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={subjectsListHead(false, orderBy === "abbr")}
                          onClick={() => handleRequestSort("abbr")}
                        >
                          <Typography sx={mr10}>
                            ABBREVIATION {renderSortChevron("abbr")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={subjectsListHead(false, orderBy === "subject")}
                          onClick={() => handleRequestSort("subject")}
                        >
                          <Typography sx={klassesListSubject}>
                            SUBJECT / COURSE {renderSortChevron("subject")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={subjectsListHead(
                            false,
                            orderBy === "description"
                          )}
                          onClick={() => handleRequestSort("description")}
                        >
                          <Typography sx={klassesListDescription}>
                            DESCRIPTION {renderSortChevron("description")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={subjectsListHead(
                            false,
                            orderBy === "assignments"
                          )}
                          onClick={() => handleRequestSort("assignments")}
                        >
                          <Typography>
                            ASSIGNMENTS {renderSortChevron("assignments")}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={subjectsListHead(false, orderBy === "students")}
                          onClick={() => handleRequestSort("students")}
                        >
                          <Typography>
                            STUDENTS {renderSortChevron("students")}
                          </Typography>
                        </TableCell>
                        <TableCell sx={subjectsListHead} />
                        <TableCell sx={subjectsListHead} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {klasses.map((klass) => (
                        <TableRow hover key={klass.id}>
                          <TableCell sx={subjectsListCellName}>
                            <Link
                              to={klassLink(klass)}
                              underline="none"
                              component={RouterLink}
                            >
                              <Typography>
                                <span>{klass.name}</span>
                              </Typography>
                            </Link>
                          </TableCell>
                          <TableCell sx={subjectsListCell()}>
                            <Typography>
                              <span>{klass.abbreviation}</span>
                            </Typography>
                          </TableCell>
                          <TableCell sx={subjectsListCell()}>
                            <Typography>{klass.subject.name}</Typography>
                          </TableCell>
                          <TableCell sx={subjectsListCell()}>
                            <Typography>{klass.description}</Typography>
                          </TableCell>
                          <TableCell align="center" sx={subjectsListCell(true)}>
                            <Typography sx={mr30}>
                              {klass.assignments_count}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={subjectsListCell(true)}>
                            <Typography sx={mr30}>
                              {klass.students_count}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {klass.lms_id && (
                              <Box
                                sx={gcLogoContainer}
                                {...(klass.lms_link && {
                                  onClick: () =>
                                    window.open(klass.lms_link, "_blank"),
                                })}
                              >
                                <img src={gcLogoPath} alt="logo" />
                              </Box>
                            )}
                          </TableCell>
                          {managePermission && (
                            <TableCell sx={klassesSettingsCell}>
                              <IconButton
                                sx={black}
                                aria-label="more"
                                id={`long-button-${klass.id}`}
                                aria-controls={
                                  menuAnchors[klass.id]
                                    ? `long-menu-${klass.id}`
                                    : undefined
                                }
                                aria-expanded={
                                  menuAnchors[klass.id] ? "true" : undefined
                                }
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, klass.id)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`long-menu-${klass.id}`}
                                anchorEl={menuAnchors[klass.id]}
                                open={Boolean(menuAnchors[klass.id])}
                                onClose={() => handleClose(klass.id)}
                              >
                                <MenuItem
                                  autoFocus={false}
                                  onClick={() => {
                                    handleKlassesDrawer(true, klass.id);
                                    handleClose(klass.id);
                                  }}
                                >
                                  Edit Class
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleGoogleLogin(klass.id);
                                    handleClose(klass.id);
                                  }}
                                >
                                  Google Classroom
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography align="center">No subjects found.</Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Drawer anchor="right" open={klassesDrawerStatusEdit}>
        <Box sx={rightDrawerContainer}>
          {activeKlass ? (
            <KlassForm
              teacherId={teacher.id}
              schoolId={schoolId}
              handleKlassesDrawer={handleKlassesDrawer}
              activeKlass={activeKlass}
              setSnackBarOpen={setSnackBarOpen}
              termId={searchParams.get("term")}
              currentSchool={currentSchool}
            />
          ) : (
            <CircularProgress sx={loader} size={100} />
          )}
        </Box>
      </Drawer>
    </Authorize>
  );
}
