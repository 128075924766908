import { tableCellClasses } from "@mui/material";

const tableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  "& .MuiTableCell-head": {
    paddingY: "10px",
  },
  "& .MuiTableCell-body": {
    color: "black",
    paddingY: "8px",
  },
};

const gradesTableStyles = {
  ...tableStyles,
  "& .MuiTableCell-head": {
    color: "#000000",
    paddingY: "10px",
    fontSize: 12,
    fontWeight: "700 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    paddingX: "12px",
    paddingY: "8px",
    borderBottom: "1px solid #D6D6D6",
    "& .MuiTypography-root": {
      fontSize: 13,
      color: "black",
    },
  },
};

const assignmentsTableStyles = {
  "& table": {
    width: "100%",
    margin: "10px 20px",
    fontSize: 12,
    color: "black",
  },

  "& th": {
    textAlign: "left",
    textTransform: "uppercase",
  },

  "& th:first-of-type, & td:first-of-type": {
    minWidth: "280px",
  },

  "& th:nth-of-type(n+2), & td:nth-of-type(n+2)": {
    width: "15%",
  },

  "& th, & td": {
    padding: "0 8px",
    verticalAlign: "top",
  },

  "& tr.missing": {
    color: "red",
  },
};

const schoolImage = {
  height: "85px",
  marginRight: "0.5rem",
};
const schoolText = {
  color: "black",
  fontSize: "34px",
  lineHeight: "34px",
  height: "fit-content",
};
const reportSubtitle = {
  fontSize: "16px",
  fontWeight: 700,
  color: "black",
};
export {
  tableStyles,
  schoolText,
  schoolImage,
  reportSubtitle,
  gradesTableStyles,
  assignmentsTableStyles,
};
