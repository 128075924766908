import axios from "../utils/axios-util";

const progressReportsEndpoint = "/api/progress_reports";

const fetchProgressReport = async (id) => {
  const { data } = await axios.get(`${progressReportsEndpoint}/${id}`);
  return data;
};

export default {
  fetchProgressReport,
};
