import { useEffect, useState } from "react";
import permissionsService from "../service/permissionsService";
import { removeToken } from "../utils/constants/auth";

export default function usePermissions(schoolId, teacher) {
  const [permissions, setPermissions] = useState([]);

  const fetchData = async () => {
    try {
      const result = await permissionsService.fetchCurrentUserPermission({
        school_id: schoolId,
      });

      setPermissions(result);
    } catch (error) {
      if (error.response.status === 401) {
        removeToken();
        window.location.href = `${window.origin}/login`;
      }
    }
  };
  useEffect(() => {
    (async () => {
      if (schoolId && teacher) {
        await fetchData();
      }
    })();
  }, [schoolId, teacher]);

  return permissions;
}
