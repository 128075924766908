import { tableCellClasses, tableRowClasses } from "@mui/material";

const contactsTableStyles = {
  [`& .${tableCellClasses.root}`]: {
    borderBottom: "none",
  },
  [`& .${tableRowClasses.root}:not(:last-child)`]: {
    borderBottom: "1px solid #d9d9d9",
  },
  "& .MuiTableCell-head": {
    color: "#8f8e8e",
    fontWeight: "500 !important",
    textTransform: "uppercase",
  },
  "& .MuiTableCell-body": {
    color: "black",
    fontSize: 14,
  },
};
const studentTableBox = {
  width: "100%",
  borderTop: "1px solid black",
  position: "relative",
};

const studentTablePaper = { width: "100%", py: "10px" };

const studentTableHeaderCell = (isCompact) => ({
  cursor: "pointer",
  px: "10px",
  width: "1%",
  whiteSpace: "no-wrap",
  ...(isCompact && {
    py: "10px",
    pr: "20px",
  }),
});

const userCardAvatar = {
  width: "8rem",
  height: "8rem",
  borderRadius: "50%",
  border: "1px solid",
  display: "flex",
  overflow: "hidden",
  justifyContent: "center",
  backgroundColor: "#ffffff",
};

const studentTableCellWide = (isCompact, cursor) => ({
  ...(cursor && { cursor: "pointer" }),
  width: "400px",
  textAlign: "left",
  ...(isCompact && {
    width: "250px",
    py: "10px",
  }),
});

const studentTableSpan = (isCompact) => ({
  fontSize: 14,
  color: "#000000",
  ...(isCompact && {
    fontWeight: 500,
    fontSize: 16,
  }),
});

const studentTableCell = {
  width: "150px",
  padding: "23px 0",
  textAlign: "left",
  fontSize: 16,
  color: "black",
};

const studentTableCellTeacher = {
  ...studentTableCell,
  width: "200px",
};

const studentTableAEID = {
  ...studentTableCell,
  textAlign: "left",
  fontSize: 16,
  color: "black",
};

const studentTableHeadCell = (sortable) => ({
  fontSize: 14,
  color: "#808080",
  cursor: sortable ? "pointer" : "inherit",
  borderBottom: "none",
});

const studentTableHeadCellActive = (sortable) => ({
  ...studentTableHeadCell(sortable),
  color: "black",
});

const studentsTableHeaderText = {
  display: "flex",
  alignItems: "center",
};

const emergencyContactsContainer = {
  px: "1rem",
  maxHeight: "10rem",
  overflow: "auto",
};

const emergencyContactsHeading = {
  position: "sticky",
  top: "0",
  background: "white",
  width: "100%",
  color: "#F50057",
};

const boxStyle = {
  maxHeight: "200vh",
  minHeight: "200vh",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: 0,
  },
};

export {
  studentTableBox,
  studentTablePaper,
  studentTableHeaderCell,
  studentTableCellWide,
  studentTableSpan,
  studentTableCell,
  studentTableHeadCell,
  studentTableHeadCellActive,
  studentsTableHeaderText,
  studentTableCellTeacher,
  userCardAvatar,
  contactsTableStyles,
  emergencyContactsHeading,
  emergencyContactsContainer,
  boxStyle,
  studentTableAEID,
};
