import React, { useState, useRef, useEffect, useContext } from "react";
import {
  useParams,
  Link as RouterLink,
  useSearchParams,
} from "react-router-dom";
import {
  AppBar,
  Badge,
  Box,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Link,
  Avatar,
  IconButton,
  Divider,
  ListItemIcon,
  ButtonBase,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Logout from "@mui/icons-material/Logout";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import TopNavDrawer from "./Drawer";
import { removeToken, storedToken } from "../../utils/constants/auth";
import {
  appBarBox,
  menuDefaultColor,
  navBarAccountIcon,
  navBarAvatar,
  navBarIconButton,
  navBarLinks,
  navBarLinksGrid,
  navBarMenu,
  navBarSettingsBadge,
  navBarSettingsIcon,
  navBarTypography,
  styledAppBar,
  navBarSchoolLink,
  menuDefaultBackground,
} from "./TopNavBar.style";
import { flex, mr10 } from "../sharedStyles";
import axios from "../../utils/axios-util";
import teachersService from "../../service/staffsService";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import { NavigationContext } from "../../context/NavigationContext";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import usersService from "../../service/usersService";
import schoolsService from "../../service/schoolsService";

export default function TopNavBar({
  toggleDrawer,
  currentSchool,
  hasUnreadMessages,
  unreadNotifications,
  teacher,
  dataRollupSyncInitiated,
  setDataRollupSyncInitiated,
}) {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const schoolId = params.school_id;
  const navigationContext = useContext(NavigationContext);
  const [hovering, setHovering] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverEl, setPopoverEl] = useState(null);
  const [showTakeAttendanceLink, setShowTakeAttendanceLink] = useState(false);
  const openQuickMenu = Boolean(anchorEl);
  const openPopover = Boolean(popoverEl);
  const secondaryBarRef = useRef();
  const { hasPermission, hasAnyPermissionType } =
    useContext(PermissionsContext);

  const termsPermission = hasAnyPermissionType(APP_PERMISSIONS.TERMS);

  const klassSchedulePermission = hasAnyPermissionType(
    APP_PERMISSIONS.KLASS_SCHEDULES
  );

  const schoolDayPermission = hasAnyPermissionType(APP_PERMISSIONS.SCHOOL_DAYS);

  const dayTemplatesPermission = hasAnyPermissionType(
    APP_PERMISSIONS.DAY_TEMPLATE
  );

  const admissionsPermission = hasAnyPermissionType(APP_PERMISSIONS.ADMISSIONS);

  const allStudentsPermission = hasPermission(
    APP_PERMISSIONS.ALL_STUDENTS,
    PERMISSION_TYPES.MANAGE
  );

  const dataRollupSyncPermission = hasPermission(
    APP_PERMISSIONS.DATAROLLUP_SYNC,
    PERMISSION_TYPES.MANAGE
  );
  const schedulePermission =
    termsPermission ||
    klassSchedulePermission ||
    schoolDayPermission ||
    dayTemplatesPermission;

  const overlayType = {
    popover: 1,
    quickMenu: 2,
  };

  const admissionsPath = currentSchool?.admission_version_advanced
    ? `/school/${schoolId}/admissions/students/inquiries`
    : `/school/${schoolId}/admissions/enrollments/enrolled-students`;

  const handleClick = (type) => (event) => {
    if (type === overlayType.popover) {
      setPopoverEl(secondaryBarRef.current);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (type) => () => {
    if (type === overlayType.popover) {
      setPopoverEl(null);
    } else {
      setAnchorEl(null);
    }
  };

  const handleLogout = async () => {
    try {
      const payload = await axios.delete("/users/sign_out");

      if (payload.data.message === "You are logged out.") {
        removeToken(storedToken);
        window.location.replace("/login");
      }
    } catch (e) {
      window.location.reload();
    }
  };

  useEffect(() => {
    (async () => {
      const response = await teachersService.teacherHasHomeroomStudents({
        params: { school_id: schoolId },
      });
      if (response) {
        setShowTakeAttendanceLink(response.has_homeroom_students);
      }
    })();
  }, []);

  const generateScheduleLink = () => {
    if (termsPermission) return `/school/${schoolId}/schedules/terms`;
    if (schoolDayPermission) return `/school/${schoolId}/schedules/school-days`;
    if (dayTemplatesPermission)
      return `/school/${schoolId}/schedules/day-templates`;
    if (klassSchedulePermission)
      return `/school/${schoolId}/schedules/class-schedules`;
    return `#`;
  };

  const navigateTo = (path) => {
    navigationContext.navigateTo(path);
  };

  const hasTermParam =
    searchParams.get("term") && searchParams.get("term") !== "select";

  const handleChangePassword = async () => {
    try {
      const response = await usersService.sendResetPasswordInstructions({
        email: teacher.school_email,
      });

      if (response) {
        navigateTo(`/forgot-password?isSent=true`);
      }
    } catch (e) {
      window.location.reload();
    }
  };

  const dataRollupSync = async () => {
    if (dataRollupSyncInitiated) return;
    try {
      const response = await schoolsService.syncDataRollup(schoolId);

      if (response) {
        setDataRollupSyncInitiated(true);
      }
    } catch (e) {
      console.error("Error syncing data rollup", e);
    }
  };

  return (
    <Box sx={appBarBox(openPopover)}>
      <AppBar position="relative" sx={styledAppBar}>
        <Toolbar variant="dense">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <div style={flex}>
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={navBarIconButton}
                  onClick={toggleDrawer(true)}
                >
                  <SwapVertIcon />
                </IconButton>
                <Link
                  to={`/school/${schoolId}/entry-screen`}
                  component={RouterLink}
                  sx={navBarSchoolLink}
                >
                  <Typography noWrap component="div" sx={navBarTypography}>
                    {currentSchool.name}
                  </Typography>
                </Link>
                {dataRollupSyncPermission && (
                  <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    sx={{ ml: 2 }}
                    onClick={dataRollupSync}
                  >
                    {dataRollupSyncInitiated ? (
                      <TimelapseIcon />
                    ) : (
                      <UploadFileIcon
                        sx={{
                          color: hovering ? "#FDBB30" : "white",
                          transition: "0.3s",
                        }}
                      />
                    )}
                    <Typography
                      // onMouseEnter={() => setHovering(true)}
                      // onMouseLeave={() => setHovering(false)}
                      sx={{
                        ml: 1,
                        color: "white",
                        opacity: hovering ? 1 : 0,
                        transition: "0.3s",
                      }}
                    >
                      {dataRollupSyncInitiated
                        ? "DataRollup sync initiated"
                        : "Sync DataRollup"}
                    </Typography>
                  </IconButton>
                )}
              </div>
            </Grid>
            <Grid item xs textAlign="right">
              <IconButton
                color="inherit"
                onClick={() => navigateTo(`/school/${schoolId}/notifications`)}
              >
                <Badge color="error" badgeContent={unreadNotifications?.total}>
                  <Typography sx={mr10}>Notifications</Typography>
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <IconButton
                color="inherit"
                onClick={() => navigateTo(`/school/${schoolId}/messages/inbox`)}
              >
                <Badge
                  variant="dot"
                  color="error"
                  invisible={!hasUnreadMessages}
                >
                  <Typography sx={mr10}>Messages</Typography>
                  <MailOutlineIcon />
                </Badge>
              </IconButton>
              <IconButton onClick={handleClick(overlayType.quickMenu)}>
                <Badge
                  overlap="circular"
                  aria-controls={openQuickMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openQuickMenu ? "true" : undefined}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Badge sx={navBarSettingsBadge}>
                      <SettingsIcon sx={navBarSettingsIcon} />
                    </Badge>
                  }
                >
                  <Avatar sx={navBarAvatar}>
                    <AccountCircleIcon sx={navBarAccountIcon} />
                  </Avatar>
                </Badge>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Grid
          container
          sx={navBarLinksGrid}
          justifyContent="center"
          ref={secondaryBarRef}
        >
          {hasAnyPermissionType(APP_PERMISSIONS.SCHOOL_PROFILE) && (
            <Box mx={1} my={1}>
              <ButtonBase
                underline="none"
                onClick={() => navigateTo(`/school/${schoolId}/school/profile`)}
              >
                <Typography sx={navBarLinks}>School Profile</Typography>
              </ButtonBase>
            </Box>
          )}
          {!hasAnyPermissionType(APP_PERMISSIONS.SCHOOL_PROFILE) &&
            hasAnyPermissionType(APP_PERMISSIONS.SECURITY) && (
              <Box mx={1} my={1}>
                <ButtonBase
                  onClick={() =>
                    navigateTo(`/school/${schoolId}/school/security-groups`)
                  }
                >
                  <Typography sx={navBarLinks}>School Profile</Typography>
                </ButtonBase>
              </Box>
            )}
          {hasAnyPermissionType(APP_PERMISSIONS.STAFF_LIST) && (
            <Box mx={1} my={1}>
              <ButtonBase
                onClick={() => navigateTo(`/school/${schoolId}/staff/staffs`)}
              >
                <Typography sx={navBarLinks}>Staff</Typography>
              </ButtonBase>
            </Box>
          )}
          {admissionsPermission && (
            <Box mx={1} my={1}>
              <ButtonBase onClick={() => navigateTo(admissionsPath)}>
                <Typography sx={navBarLinks}>Admissions</Typography>
              </ButtonBase>
            </Box>
          )}

          {hasAnyPermissionType(APP_PERMISSIONS.COURSES) &&
            (currentSchool.has_secondary_students ||
              currentSchool.has_elementary_courses) && (
              <Box mx={1} my={1}>
                <ButtonBase
                  onClick={() => navigateTo(`/school/${schoolId}/courses`)}
                >
                  <Typography sx={navBarLinks}>Courses</Typography>
                </ButtonBase>
              </Box>
            )}

          {schedulePermission && (
            <Box mx={1} my={1}>
              <ButtonBase onClick={() => navigateTo(generateScheduleLink())}>
                <Typography sx={navBarLinks}>Scheduling</Typography>
              </ButtonBase>
            </Box>
          )}
          {hasAnyPermissionType(APP_PERMISSIONS.REPORT_SCREEN) && (
            <Box mx={1} my={1}>
              <ButtonBase
                onClick={() => navigateTo(`/school/${schoolId}/reports`)}
              >
                <Typography sx={navBarLinks}>Reports</Typography>
              </ButtonBase>
            </Box>
          )}
          <Divider orientation="vertical" flexItem />
          {(showTakeAttendanceLink || allStudentsPermission) &&
            hasAnyPermissionType(APP_PERMISSIONS.ATTENDANCE) && (
              <Box mx={1} my={1}>
                <ButtonBase
                  underline="none"
                  onClick={() => navigateTo(`/school/${schoolId}/attendance`)}
                >
                  <Typography sx={navBarLinks}>Attendance</Typography>
                </ButtonBase>
              </Box>
            )}
          <Box mx={1} my={1}>
            <ButtonBase
              onClick={() =>
                navigateTo(
                  `/school/${schoolId}/students${
                    hasTermParam ? `?term=${searchParams.get("term")}` : ""
                  }`
                )
              }
            >
              <Typography sx={navBarLinks}>Students</Typography>
            </ButtonBase>
          </Box>
          {hasAnyPermissionType(APP_PERMISSIONS.MY_SUBJECTS) && (
            <Box mx={1} my={1}>
              <ButtonBase
                onClick={() =>
                  navigateTo(
                    `/school/${schoolId}/current-subjects${
                      hasTermParam ? `?term=${searchParams.get("term")}` : ""
                    }`
                  )
                }
              >
                <Typography sx={navBarLinks}>Subjects</Typography>
              </ButtonBase>
            </Box>
          )}

          {hasAnyPermissionType(APP_PERMISSIONS.MY_KLASSES) && (
            <Box mx={1} my={1}>
              <ButtonBase
                onClick={() =>
                  navigateTo(
                    `/school/${schoolId}/current-classes${
                      hasTermParam ? `?term=${searchParams.get("term")}` : ""
                    }`
                  )
                }
              >
                <Typography sx={navBarLinks}>Classes</Typography>
              </ButtonBase>
            </Box>
          )}
          {hasAnyPermissionType(APP_PERMISSIONS.KLASSES_ASSIGNMENTS) && (
            <Box mx={1} my={1}>
              <ButtonBase
                onClick={() =>
                  navigateTo(
                    `/school/${schoolId}/assignments${
                      hasTermParam ? `?term=${searchParams.get("term")}` : ""
                    }`
                  )
                }
              >
                <Typography sx={navBarLinks}>Assignments</Typography>
              </ButtonBase>
            </Box>
          )}

          <Box mx={1} my={1}>
            <ButtonBase
              onClick={() => navigateTo(`/school/${schoolId}/report-cards`)}
            >
              <Typography sx={navBarLinks}>Report Cards</Typography>
            </ButtonBase>
          </Box>
          {(hasAnyPermissionType(
            APP_PERMISSIONS.SOCIAL_DEVELOPMENT_WORK_ETHIC
          ) ||
            hasPermission(
              APP_PERMISSIONS.ALL_STUDENTS,
              PERMISSION_TYPES.MANAGE
            )) && (
            <Box mx={1} my={1}>
              <ButtonBase
                onClick={() =>
                  navigateTo(`/school/${schoolId}/social-development`)
                }
              >
                <Typography sx={navBarLinks}>
                  Social Development/Work Ethic
                </Typography>
              </ButtonBase>
            </Box>
          )}

          {hasAnyPermissionType(APP_PERMISSIONS.PK_TRACKER) &&
            currentSchool.has_pk_tracker && (
              <Box mx={1} my={1}>
                <ButtonBase
                  onClick={() =>
                    navigateTo(`/school/${schoolId}/pk-development-tracker`)
                  }
                >
                  <Typography sx={navBarLinks}>
                    Pre-K Development Tracker
                  </Typography>
                </ButtonBase>
              </Box>
            )}
        </Grid>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openQuickMenu}
        onClose={handleClose(overlayType.quickMenu)}
        onClick={handleClose(overlayType.quickMenu)}
        PaperProps={{
          elevation: 0,
          sx: navBarMenu,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Typography
            sx={menuDefaultColor}
          >{`${teacher.first_name} ${teacher.last_name}`}</Typography>
        </MenuItem>
        <Divider sx={menuDefaultBackground} variant="middle" />
        <MenuItem onClick={() => handleChangePassword()}>
          <Typography sx={menuDefaultColor}>Update Password</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" sx={menuDefaultColor} />
          </ListItemIcon>
          <Typography sx={menuDefaultColor}>Sign Out</Typography>
        </MenuItem>
      </Menu>
      <TopNavDrawer
        open={openPopover}
        anchorEl={popoverEl}
        onClose={handleClose(overlayType.popover)}
      />
    </Box>
  );
}
