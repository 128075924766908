import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  reportSubtitle,
  schoolImage,
  schoolText,
} from "./ProgressReport.style";

export default function StudentProgressHeader({
  headerData,
  schoolName,
  schoolOrgCode,
}) {
  const [imageExists, setImageExists] = useState(true);

  const handleImageLoad = () => {
    setImageExists(true);
  };

  const handleImageError = () => {
    setImageExists(false);
  };
  return (
    <>
      <Grid container justifyContent="end" paddingRight="2rem">
        <Typography sx={reportSubtitle}>
          Progress Report | {`${headerData?.termName} ${headerData?.yearName}`}
        </Typography>
      </Grid>
      <Grid container alignItems="end">
        {imageExists && schoolOrgCode && (
          <img
            alt="School logo"
            style={schoolImage}
            src={`https://nad-bigtincan.s3.us-west-2.amazonaws.com/school-logos/${schoolOrgCode}.jpg`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}
        <Typography sx={schoolText}>{schoolName}</Typography>
      </Grid>
    </>
  );
}
