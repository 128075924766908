const filtersBorder = {
  borderTop: "solid 1px",
  paddingTop: "0px !important",
  paddingLeft: "0px !important",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "space-between",
};

const cancelButtonStyle = (showClearButton) => ({
  visibility: showClearButton ? "inherit" : "hidden",
});

const studentListContainer = (isCompact) => ({
  px: isCompact && "10px !important",
});

const filterButton = (active = false) => ({
  borderRadius: 0,
  borderRight: "1px solid black",
  textTransform: "capitalize",
  color: active ? "white" : "#49454F",
  maxWidth: "35px",
  maxHeight: "35px",
  minWidth: "27px",
  minHeight: "35px",
  backgroundColor: active ? "black" : "white",
  "&:hover": {
    backgroundColor: active ? "black" : "white",
    color: active ? "white" : "#49454F",
  },
});

const editButton = {
  maxHeight: "15px",
  minHeight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "5px",
};

export {
  filtersBorder,
  cancelButtonStyle,
  studentListContainer,
  filterButton,
  editButton,
};
